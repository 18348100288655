import { useEffect, useState } from 'react';
import './App.scss';
import Loader from './components/loader/Loader';
import i18n from './language/i18n';
import translation from './language/translation.json';
import { IComponent } from './models/IComponent';
import { IGetComponentResult } from './models/IGetComponentResult';
import EditorView from './pages/editorView/EditorView';
import ErrorPage from './pages/errorPage/ErrorPage';
import WebView from './pages/webView/WebView';
import { HttpRequests } from './utils/HttpRequests';
import { Utils } from './utils/Utils';

function App() {

  const [error, setError] = useState(false);
  const [isSeasonEffect, setIsSeasonEffect] = useState(false);
  const [component, setComponent] = useState<IComponent>();
  const [componentQrCode, setComponentQrCode] = useState<string>('');
  const [selectedLanguage, setSelectedLanguage] = useState(translation['language'][i18n.language])
  const { isEditorView, redirectUrl } = Utils.getRedirectInfos()
  const getComponent = async () => {
    try {
      const result: IGetComponentResult = await new HttpRequests().getComponent(window.location.href);
      if (result.success) {
        setComponent(result.component);
        setComponentQrCode(result.componentQrCode);
      } else {
        window.location.href = redirectUrl;
      }
    } catch (error) {
      console.error('Error on getting component', error);
      setError(true);
    }
  }

  const getIsSeasonEffect = async () => {
    try {
      setIsSeasonEffect(await new HttpRequests().getIsSeasonEffect())
    } catch (error) {
      console.log('Error on getting season effect: ' + error);
    }
  }

  useEffect(() => {
    getIsSeasonEffect()
    if (!isEditorView) {
      getComponent();
    }
  }, [])

  const loaderView = () => {
    return (
      <div className="app_loader_wrapper">
        <Loader />
      </div>
    );
  }

  const webView = () => {
    if (component) {
      return <WebView
        isSeasonEffect={isSeasonEffect}
        component={component}
        componentQrCode={componentQrCode}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      />
    } else {
      return loaderView();
    }
  }

  const loadView = () => {
    if (error)
      return <ErrorPage />

    if (isEditorView)
      return <EditorView
        isSeasonEffect={isSeasonEffect}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      />

    return webView();
  }

  return (loadView());
}

export default App;
