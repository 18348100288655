import React from 'react'
import './QrCodeComponent.scss'

function QrCodeComponent({ componentQrCode }) {
    return (<>
        {componentQrCode && <div className='qr_code_wrapper'>
            <img className='component_qr_code' src={decodeURIComponent(componentQrCode)} alt='component_qr_code' />
        </div>}
    </>
    )
}

export default QrCodeComponent