import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translation from "./translation.json";
import { Utils } from "../utils/Utils";

const resources = {
    de: {
        translation: translation
    },
    en: {
        translation: translation
    },
};
const lang = Utils?.getBrowserLanguage()
i18n
    .use(initReactI18next)
    .init({
        debug: false,
        lng: lang,
        fallbackLng: 'en', // Fallback language
        resources,
    });

if (!Utils?.isLanguageSupported(lang)) {
    i18n.changeLanguage('en');
}

export default i18n;
