import { FormTexts } from '../../../language/Texts'
import { Utils } from '../../../utils/Utils'
import './FormTitle.scss'

interface FormTitleProps {
    type: string
}

function FormTitle({ type }: FormTitleProps) {
    const title = Utils.isTypeBatteryPass(type) ? FormTexts.formTitleBatteryPass : FormTexts.formTitle
    const subTitle = Utils.isTypeBatteryPass(type) ? FormTexts.formSubTitleBatteryPass : FormTexts.formSubTitle

    return (
        <>
            <div className="form_title">
                {title}
            </div>
            <div className="form_sub_title">
                {subTitle}
            </div>
        </>
    )
}

export default FormTitle
