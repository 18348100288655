import { IGetComponentResult } from '../models/IGetComponentResult';

export class HttpRequests {

    async getComponent(identificationLink: string): Promise<IGetComponentResult> {
        // return axios.get('/api/v1/getcomponent', {
        //     params: { identificationLink },
        // })...

        // If use axios this code works as expected but on fetch parameters is decoded automatically
        // That is why we need to manually encode it : ...Link=${encodeURIComponent(identificationLink)}

        try {
            const url = `/api/v1/getcomponent?identificationLink=${encodeURIComponent(identificationLink)}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            });
            if (!response.ok) {
                const errorResponse = await response.json();
                throw errorResponse
            }
            return await response.json();
        } catch (error) {
            console.error('Error on getting components:', error);
            throw error;
        }
    }

    async saveComponent(data: FormData): Promise<{ success: boolean }> {
        try {
            const response = await fetch('/api/v1/savecomponent', {
                method: 'POST',
                body: data,
            });
            if (!response.ok) {
                const errorResponse = await response.json();
                throw errorResponse
            }
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.log('Error on saving component', error);
            throw error;
        }
    }

    async getIsSeasonEffect(): Promise<boolean> {
        try {
            const response = await fetch('/api/v1/getseasoneffect');
            if (!response.ok) {
                const errorResponse = await response.json();
                throw errorResponse
            }
            return await response.json();
        } catch (error) {
            console.log('Error fetching season effect', error);
            throw error;
        }
    }
}