import { faCloudUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import './DropzoneComp.scss'
import { FormTexts } from '../../../language/Texts'

interface IFileLoaded { onFileLoaded: (file: File) => void }

export default function DropzoneComp({ onFileLoaded }: IFileLoaded) {
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file: File) => {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                onFileLoaded(file)
            }
            reader.readAsArrayBuffer(file)
        })
    }, [])
    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    return (
        <div {...getRootProps()} className='dropzone_wrapper' >
            <input {...getInputProps()} />
            <FontAwesomeIcon className="upload_icon" icon={faCloudUpload} />
            <p className='drop_text'>{FormTexts.dragDropText}</p>
        </div>
    )
}