import { Button } from 'react-bootstrap'
import { FormTexts } from '../../../language/Texts'
import Loader from '../../loader/Loader'

interface ButtonProps {
    isPrivacyPolicyAccepted: boolean,
    alertOpt: { message: string, loader: boolean },
}

function FooterButton({ isPrivacyPolicyAccepted, alertOpt }: ButtonProps) {
    return (
        <div className="form_button_wrapper">
            <Button
                disabled={!isPrivacyPolicyAccepted || alertOpt.loader}
                className='form_save_button'
                type='submit'>
                {FormTexts.freeTest}
            </Button>
            <span
                style={{ display: alertOpt.message ? 'inline' : 'none' }}
                className='alert_text'>
                {alertOpt.message}
            </span>
            {alertOpt.loader && <Loader />}
        </div>
    )
}

export default FooterButton
