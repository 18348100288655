import moment from 'moment'
import 'moment/locale/de'
import 'moment/locale/en-gb'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/pl'
import 'moment/locale/pt'
import 'moment/locale/ru'
import 'moment/locale/zh-cn'

import { WebViewTexts } from '../../../language/Texts'
import i18n from '../../../language/i18n'
import { Utils } from '../../../utils/Utils'
import InfoPair from '../infoPair/InfoPair'
import './General.scss'

interface GeneralProps {
    serialNumber: string | undefined,
    materialNumber: string | undefined,
    date: string | undefined,
    manufacturerUrl: string | undefined,
    manufacturerName: string | undefined,
    isEditorView: boolean
}

function General(props: GeneralProps) {

    return (
        <div className='info_part'>
            <div className={Utils.getInfoPartTitleClassName(props.isEditorView)}>
                {WebViewTexts.general}
            </div>
            <div className='info_pair_wrapper'>
                <InfoPair title={WebViewTexts.serialNumber} info={props.serialNumber} isEditorView={props.isEditorView} />
                <InfoPair title={WebViewTexts.materialNumber} info={props.materialNumber} isEditorView={props.isEditorView} />
                <InfoPair
                    title={WebViewTexts.manufacturerDate}
                    info={props.date &&
                        moment(props.date, "YYYY-MM-DD").locale(i18n.language).format('ddd, D. MMM YYYY')}
                    isEditorView={props.isEditorView} />
                <InfoPair title={WebViewTexts.manufacturerName} info={props.manufacturerName} isEditorView={props.isEditorView} />
                <InfoPair title={WebViewTexts.manufacturerWebseite} info={props.manufacturerUrl} isEditorView={props.isEditorView} />
            </div>
        </div >
    )
}

export default General
