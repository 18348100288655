import { faDownload, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WebViewTexts } from '../../../language/Texts';
import { IFile } from '../../../models/IFile';
import { Utils } from '../../../utils/Utils';
import './DocumentComponent.scss';

interface DocumentPartProps {
    files: File[] | IFile[] | undefined,
    isEditorView: boolean
}
function DocumentComponent({ files, isEditorView }: DocumentPartProps) {

    const getDownloadUrl = (file: File | IFile) => {
        if (file instanceof File) {
            return URL.createObjectURL(file);
        } else {
            return decodeURIComponent(file.downloadUrl);
        }
    }
    return (
        <>
            {files && files?.length > 0 && <div className='info_part'>
                <div className={Utils.getInfoPartTitleClassName(isEditorView)}>{WebViewTexts.documents}</div>
                <div className='info_pair_wrapper'>
                    <div className='info_pair'>
                        {files?.map((file, index) => (
                            <div key={index} className='document_item_wrapper'>
                                <div className="document_info_wrapper">
                                    <FontAwesomeIcon className="file_icon" icon={faFile} />
                                    <div className="document_info">
                                        <div className={isEditorView ? 'info editor_info' : 'info'}>{decodeURIComponent(file.name)}</div>
                                        <div className={isEditorView ? 'info_title editor_info_title' : 'info_title'}>{(file.size / 1000000).toFixed(2)} MB</div>
                                    </div>
                                </div>
                                <a
                                    className='download_icon_wrapper'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href={getDownloadUrl(file)}
                                    download={decodeURIComponent(file.name)}
                                >
                                    <FontAwesomeIcon className="download_icon" icon={faDownload} />
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>}
        </>
    )
}

export default DocumentComponent
