import { useState } from 'react'
import { Form } from 'react-bootstrap'
import { AlertTexts, FormTexts, WebViewTexts } from '../../../language/Texts'
import { IFormComponentProps } from '../../../models/IFormComponentProps'
import ColorPicker from '../colorPicker/ColorPicker'
import DateComponent from '../dateComponent/DateComponent'
import DocumentComponent from '../documentComponent/DocumentComponent'
import FormFooter from '../formFooter/FormFooter'
import FormTitle from '../formTitle/FormTitle'
import IconComponent from '../iconComponent/IconComponent'
import InputComponent from '../inputComponent/InputComponent'
import KeyValues from '../keyValues/KeyValues'
import SuccessModal from '../modal/SuccessModal'
import './FormComponent.scss'
import { formSubmit } from './FormSubmit'

function FormComponent(props: IFormComponentProps) {
    const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false)
    const [alerOpt, setAlertOpt] = useState({ message: '', loader: false });
    const [modalShow, setModalShow] = useState(false);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (!isAllKeyValuesFilled()) {
            return;
        }
        formSubmit(props, setAlertOpt, setModalShow)
    }

    const isAllKeyValuesFilled = () => {
        let isAllKeyValuesFilled = true;
        for (let i = 0; i < props.keyValues.length; i++) {
            const kv = props.keyValues[i];
            if (kv.key.trim() === '' || kv.value.trim() === '') {
                isAllKeyValuesFilled = false;
                break;
            }
        }
        if (!isAllKeyValuesFilled) {
            setAlertOpt({ message: AlertTexts.fillKeyValue, loader: false });
            setTimeout(() => {
                setAlertOpt({ message: '', loader: false });
            }, 3000);
        }
        return isAllKeyValuesFilled;
    }

    return (
        <Form className='form_wrapper' onSubmit={handleSubmit} >
            <FormTitle type={props.type} />
            <InputComponent
                label={FormTexts.productName}
                value={props.productName}
                handleChange={props.setProductName} />
            <InputComponent
                label={FormTexts.serialNumberForm}
                value={props.serialNumber}
                handleChange={props.setSerialNumber}
                isRequired={true} />
            <InputComponent
                label={WebViewTexts.materialNumber}
                value={props.materialNumber}
                handleChange={props.setMaterialNumber} />

            <DateComponent productionDate={props.productionDate} setProductionDate={props.setProductionDate} />
            <IconComponent setIcon={props.setProductIcon} icon={props.productIcon} type={FormTexts.productIcon} />
            <IconComponent setIcon={props.setManufacturerIcon} icon={props.manufacturerIcon} type={FormTexts.manufacturerIcon} />
            <ColorPicker manufacturerColor={props.manufacturerColor} setManufacturerColor={props.setManufacturerColor} />

            <InputComponent
                label={WebViewTexts.manufacturerName}
                value={props.manufacturerName}
                handleChange={props.setManufacturerName} />
            <InputComponent
                label={WebViewTexts.manufacturerWebseite}
                value={props.manufacturerUrl}
                handleChange={props.setManufacturerUrl}
                type='url' />

            <KeyValues keyValues={props.keyValues} setKeyValues={props.setKeyValues} />
            <DocumentComponent files={props.documents} setFiles={props.setDocuments} />
            <FormFooter
                ownerEmail={props.ownerEmail}
                setOwnerEmail={props.setOwnerEmail}
                isPrivacyPolicyAccepted={isPrivacyPolicyAccepted}
                setIsPrivacyPolicyAccepted={setIsPrivacyPolicyAccepted}
                isAdvertisingAgreed={props.isAdvertisingAgreed}
                setIsAdvertisingAgreed={props.setIsAdvertisingAgreed}
                alertOpt={alerOpt}
            />
            <SuccessModal
                show={modalShow}
            />
        </Form>
    )
}

export default FormComponent
