import inevvoLogo from '../../../assets/inevvo_logo.png'
import { Utils } from '../../../utils/Utils'
import LanguageSelector from '../../languageSelector/LanguageSelector'
import './ManufacturerIconComp.scss'

interface IconProps {
    manufacturerIcon: { iconFile: File, iconUrl: string } | undefined,
    manufacturerUrl: string | undefined,
    isEditorView?: boolean,
    selectedLanguage?: string,
    setSelectedLanguage?: (language: string) => void
}

function ManufacturerIconComp({ manufacturerIcon, manufacturerUrl, isEditorView, selectedLanguage, setSelectedLanguage }: IconProps) {

    return (
        <div className='manufacturer_logo_wrapper'>
            {
                manufacturerIcon?.iconUrl ?
                    <a href={manufacturerUrl} target='_blank'>
                        <img className='manufacturer_logo' src={decodeURIComponent(manufacturerIcon.iconUrl)} alt='manufacturer_logo' />
                    </a> :
                    <a href={Utils.arrangeUrl(manufacturerUrl!)} target='_blank'>
                        <img className='manufacturer_logo' src={inevvoLogo} alt='inevvo_logo' />
                    </a>
            }
            {!isEditorView && <div className="language_selector webview_language_selector">
                <LanguageSelector
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                />
            </div>}

        </div >
    )
}

export default ManufacturerIconComp
