import { FormTexts } from '../../../language/Texts'

interface CheckboxProps {
    isPrivacyPolicyAccepted: boolean,
    setIsPrivacyPolicyAccepted: (isAccepted: boolean) => void,
    isAdvertisingAgreed: boolean,
    setIsAdvertisingAgreed: (isAccepted: boolean) => void
}

function FooterCheckBox({
    isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted,
    isAdvertisingAgreed, setIsAdvertisingAgreed
}: CheckboxProps) {

    const handleClick = () => {
        const mailConfig = `mailto:${'info@inevvo-solutions.com'}?subject=${'Datenschutz'}`
        window.open(mailConfig)
    }

    return (
        <div className="privacy_policy">
            <div className='checkbox_group'>
                <input
                    id="checkbox_advertising"
                    className='checkbox_input'
                    checked={isAdvertisingAgreed}
                    onChange={() => setIsAdvertisingAgreed(!isAdvertisingAgreed)}
                    type="checkbox" />
                <label className='checkbox_label' htmlFor="checkbox_advertising">
                    {FormTexts.voluntaryText_1}&nbsp;
                    <span className='email_redirect' onClick={() => handleClick()}>
                        info@inevvo-solutions.com
                    </span>
                    {FormTexts.voluntaryText_2}&nbsp;
                    <a
                        className='footer_link'
                        href='https://inevvo-solutions.com/datenschutz-allgemeine-informationen/datenschutz-webseite/'
                        target='_blank'>
                    </a>
                </label>
            </div>
            <div className='checkbox_group'>
                <input
                    id="checkbox_privacy_policy"
                    className='checkbox_input'
                    checked={isPrivacyPolicyAccepted}
                    onChange={() => setIsPrivacyPolicyAccepted(!isPrivacyPolicyAccepted)}
                    type="checkbox" />
                <label className='checkbox_label' htmlFor="checkbox_privacy_policy">
                    {FormTexts.privacyText} &nbsp;
                    <a
                        className='footer_link'
                        href='https://inevvo-solutions.com/datenschutz-allgemeine-informationen/datenschutz-webseite/'
                        target='_blank'>
                        {FormTexts.privacyLink}
                    </a>
                </label>
            </div>
        </div>
    )
}

export default FooterCheckBox
