import React from 'react'
import './InputComponent.scss'
import { FormTexts } from '../../../language/Texts'

interface InputCompProps {
    label: string
    value: string
    handleChange: (value: string) => void
    isRequired?: boolean
    type?: string
}

function InputComponent({ label, value, handleChange, isRequired = false, type = 'text' }: InputCompProps) {

    const onChange = (value: string) => {
        if (label === FormTexts.serialNumberForm) {
            value = value.replace(/[./]/g, '')
        }
        handleChange(value)
    }

    return (
        <div className="input_pair">
            <label className='input_label'>{label}</label>
            <input
                data-testid={label}
                className='input_field'
                required={isRequired}
                type={type}
                value={value}
                onChange={e => onChange(e.target.value)} />
        </div>
    )
}

export default InputComponent