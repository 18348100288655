import { faCircleMinus, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import DropzoneComp from '../dropzone/DropzoneComp';
import './DocumentComponent.scss';
import { WebViewTexts } from '../../../language/Texts';

interface DocumentComponentProps {
    files: File[] | undefined,
    setFiles: (files) => void;
}

function DocumentComponent({ files, setFiles }: DocumentComponentProps) {

    const handleFileLoaded = (file: File) => {
        setFiles((prevFiles) => [...prevFiles, file]);
    };

    const handleRemoveFile = (index: number) => {
        if (files && index >= 0 && index < files.length) {
            const updatedFiles = [...files];
            updatedFiles.splice(index, 1);
            setFiles(updatedFiles);
        }
    };

    return (
        <div className='form_document_wrapper'>
            <div className='input_label'>{WebViewTexts.documents}</div>
            <DropzoneComp onFileLoaded={handleFileLoaded} />

            {files?.map((file, index) => (
                <div className='single_document_group' key={index}>
                    <div className="document_icon_name">
                        <FontAwesomeIcon className="form_file_icon" icon={faFile} />
                        <div className="form_document_info">  {file.name}</div>
                    </div>

                    <Button className='remove_icon_wrapper' onClick={() => handleRemoveFile(index)}>
                        <FontAwesomeIcon className="remove_icon" icon={faCircleMinus} />
                    </Button>
                </div>

            ))}
        </div>
    )
}

export default DocumentComponent
