import { WebViewTexts } from "../../../language/Texts"

interface DateComponentProps {
    productionDate: string,
    setProductionDate: (date: string) => void,
}

function DateComponent({ productionDate, setProductionDate }: DateComponentProps) {

    return (
        <div className="input_pair">
            <label className='input_label'>{WebViewTexts.manufacturerDate}</label>
            <input
                data-testid='production_date'
                type="date"
                className='input_field'
                value={productionDate}
                onChange={e => setProductionDate(e.target.value)}
            />
        </div>
    )
}

export default DateComponent
