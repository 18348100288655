import { AlertTexts } from "../../../language/Texts";
import { GeneralTexts } from "../../../models/GeneralTexts";
import { IFormComponentProps } from "../../../models/IFormComponentProps";
import { HttpRequests } from "../../../utils/HttpRequests";
import { Utils } from "../../../utils/Utils";

interface FormSubmitProps {
    props: IFormComponentProps,
    setAlertOpt: (alertOpt: { message: string, type: string, loader: boolean }) => void,
    setModalShow: (modalShow: boolean) => void
}

export const formSubmit = async (
    props: FormSubmitProps['props'],
    setAlertOpt: FormSubmitProps['setAlertOpt'],
    setModalShow: FormSubmitProps['setModalShow']
) => {
    setAlertOpt({ message: '', type: '', loader: true })
    try {
        const formData = getFormData(props)
        await new HttpRequests().saveComponent(formData)
        setAlertOpt({ message: '', type: '', loader: false })
        setModalShow(true)
    } catch (error: any) {
        const message = error?.message ? Utils.errorConvertor(error.message) : AlertTexts.unexpectedError
        setAlertOpt({ message, type: 'danger', loader: false })
        resetAlert(setAlertOpt)
    }
}

const resetAlert = (setAlertOpt: FormSubmitProps['setAlertOpt']) => {
    setTimeout(() => {
        setAlertOpt({ message: '', type: '', loader: false })
    }, 5000)
}

const getFormData = (props: IFormComponentProps) => {
    const formData = new FormData();
    const data = getData(props)
    const encodedProductIconName = encodeURIComponent(props.productIcon.iconFile.name);
    const encodedManufacturerIconName = encodeURIComponent(props.manufacturerIcon.iconFile.name);

    formData.append(GeneralTexts.DATA, JSON.stringify(data))
    formData.append(GeneralTexts.PRODUCT_ICON, props.productIcon.iconFile, encodedProductIconName);
    formData.append(GeneralTexts.MANUFACTURER_ICON, props.manufacturerIcon.iconFile, encodedManufacturerIconName);

    props.documents?.forEach((document) => {
        const encodedName = encodeURIComponent(document.name);
        formData.append(GeneralTexts.DOCUMENTS, document, encodedName)
    })
    return formData
}

const getData = (props: IFormComponentProps) => {
    return {
        productName: props.productName,
        serialNumber: props.serialNumber,
        materialNumber: props.materialNumber,
        productionDate: props.productionDate,
        manufacturerColor: props.manufacturerColor,
        manufacturerUrl: props.manufacturerUrl,
        ownerEmail: props.ownerEmail,
        manufacturerName: props.manufacturerName,
        keyValues: props.keyValues,
        isAdvertisingAgreed: props.isAdvertisingAgreed,
        type: props.type
    }
}