import { FormTexts } from '../../../language/Texts'
import './ColorPicker.scss'

interface ColorPickerProps {
    manufacturerColor: string,
    setManufacturerColor: (color: string) => void,
}

function ColorPicker({ manufacturerColor, setManufacturerColor }: ColorPickerProps) {
    return (
        <div className="input_pair">
            <label className='input_label'>{FormTexts.manufacturerColor}</label>
            <div className="color_wrapper">
                <span className='color_value'>{manufacturerColor}</span>
                <input
                    data-testid='color_input'
                    type="color"
                    value={manufacturerColor}
                    onChange={e => setManufacturerColor(e.target.value)} />
            </div>
        </div>
    )
}

export default ColorPicker
