import { Button } from 'react-bootstrap'
import conexoMen from '../../../assets/conexoMen.png'
import footerBattery from '../../../assets/footerBattery.png'
import logo from '../../../assets/inevvo_logo.png'
import { WebViewTexts } from '../../../language/Texts'
import './Footer.scss'
import { Utils } from '../../../utils/Utils'

interface FooterProps {
    isEditorView: boolean,
    type: string
}

function Footer({ isEditorView, type }: FooterProps) {
    const getClassName = (className: string) => {
        if (!isEditorView) return className
        return `editor_${className} ${className}`
    }

    const title = Utils.isTypeBatteryPass(type) ? WebViewTexts.footerTitleBatteryPass : WebViewTexts.footerTitle
    const info = Utils.isTypeBatteryPass(type) ? WebViewTexts.footerInfoTextBatteryPass : WebViewTexts.footerInfoText
    const footerImage = Utils.isTypeBatteryPass(type) ? footerBattery : conexoMen
    return (
        <div className='footer_wrapper'>
            <img className='footer_logo' src={logo} alt="" />
            <div className={getClassName("footer_main")}>
                <div className={getClassName("footer_info")}>
                    <div className={getClassName("footer_info_title")}>{title}</div>
                    <div className={getClassName("footer_info_text")}>{info}</div>
                    <Button className={getClassName('footer_button')} href="https://inevvo-solutions.com/inevvo-solutions/digitales-typenschild-iec-61406/" target="_blank" >{WebViewTexts.footerButtonMoreInfoText}</Button>
                </div>
                <div className={getClassName("footer_image_part")}>
                    <img className='footer_image' src={footerImage} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Footer
