import { WebViewTexts } from "../../../language/Texts"
import { Utils } from "../../../utils/Utils"

function InfoPair({ title, info, isEditorView }) {
    const isManufacturerUrl = title === WebViewTexts.manufacturerWebseite
    return (
        <div className='info_pair'>
            <div className={isEditorView ? 'info_title editor_info_title' : 'info_title'}>{title}</div>
            {isManufacturerUrl ?
                <a href={Utils.arrangeUrl(info!)} target='_blank' className="manufacturer_link">
                    <div className={isEditorView ? 'info editor_info' : 'info'}>{info}</div>
                </a> :
                <div className={isEditorView ? 'info editor_info' : 'info'}>{info}</div>
            }
        </div>
    )
}

export default InfoPair
