import { MailIcon } from "@heroicons/react/outline";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { AiOutlineQrcode } from 'react-icons/ai';
import { AlertTexts } from "../../../language/Texts";
import './SuccessModal.scss';
import { Utils } from "../../../utils/Utils";

interface ModalProps {
    show: boolean
}

function SuccessModal({ show }: ModalProps) {

    const handleHide = () => {
        const { redirectUrl } = Utils.getRedirectInfos()
        window.location.href = redirectUrl;
    }

    const getModalText = () => {
        if (Utils.isBatteryPass()) {
            return AlertTexts.modalTextBatteryPass
        }
        return AlertTexts.modalText
    }

    return (
        <Modal
            show={show}
            onHide={handleHide}
            size="lg"
            centered
            className="modal_wrapper"
            backdrop="static" // Disable backdrop click
            keyboard={false}  // Disable keyboard interactions
            style={{ borderRadius: '50px' }}
        >
            <Modal.Body className="modal_body">
                <div className="modal_text">
                    {getModalText()}
                </div>
                <div className="mail_icon_wrapper">
                    <AiOutlineQrcode className="qr_code_icon" />
                    <MailIcon className='mail_icon' />
                </div>
                <Button
                    className="modal_button"
                    onClick={handleHide}>
                    {AlertTexts.createAgain}
                </Button>
            </Modal.Body>
        </Modal>
    );
}

export default SuccessModal
