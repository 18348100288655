import pbLogo from '../../../assets/pb.png'
import './EditorFooter.scss'

function EditorFooter() {
    return (
        <div className='editor_footer_wrapper'>
            <a href="https://inevvo-solutions.com/" target='_blank'>
                <img className='pb_logo' src={pbLogo} alt="" />
            </a>
        </div>
    )
}

export default EditorFooter
