import React from 'react'
import './ErrorPage.scss'
import errorImage from '../../assets/errorImage.gif'

function ErrorPage() {
    return (
        <div className='error_wrapper' data-testid='error_page'>
            <img className='error_image' src={errorImage} alt="Error_Gif" />
            <div className="error_text">Something went wrong !</div>
        </div>
    )
}

export default ErrorPage
