import i18n from './i18n';

export class HeaderTexts {
    private constructor() { }
}

export class GeneralTexts {
    constructor() { }
    static get login() { return i18n.t(`login.${i18n.language}`) }
}

export class WebViewTexts {
    constructor() { }
    static get attributes() { return i18n.t(`attributes.${i18n.language}`) }
    static get documents() { return i18n.t(`documents.${i18n.language}`) }
    static get footerTitle() { return i18n.t(`footerTitle.${i18n.language}`) }
    static get footerTitleBatteryPass() { return i18n.t(`footerTitleBatteryPass.${i18n.language}`) }
    static get footerInfoText() { return i18n.t(`footerInfoText.${i18n.language}`) }
    static get footerInfoTextBatteryPass() { return i18n.t(`footerInfoTextBatteryPass.${i18n.language}`) }
    static get footerButtonMoreInfoText() { return i18n.t(`footerButtonMoreInfoText.${i18n.language}`) }
    static get general() { return i18n.t(`general.${i18n.language}`) }
    static get serialNumber() { return i18n.t(`serialNumber.${i18n.language}`) }
    static get materialNumber() { return i18n.t(`materialNumber.${i18n.language}`) }
    static get manufacturerDate() { return i18n.t(`manufacturerDate.${i18n.language}`) }
    static get manufacturerName() { return i18n.t(`manufacturerName.${i18n.language}`) }
    static get manufacturerWebseite() { return i18n.t(`manufacturerWebseite.${i18n.language}`) }
}

export class FormTexts {
    constructor() { }
    static get attributes() { return i18n.t(`attributes.${i18n.language}`) }
    static get productName() { return i18n.t(`productName.${i18n.language}`) }
    static get productNameBatteryPass() { return i18n.t(`productNameBatteryPass.${i18n.language}`) }
    static get serialNumberForm() { return i18n.t(`serialNumberForm.${i18n.language}`) }
    static get formTitle() { return i18n.t(`formTitle.${i18n.language}`) }
    static get formTitleBatteryPass() { return i18n.t(`formTitleBatteryPass.${i18n.language}`) }
    static get formSubTitle() { return i18n.t(`formSubTitle.${i18n.language}`) }
    static get formSubTitleBatteryPass() { return i18n.t(`formSubTitleBatteryPass.${i18n.language}`) }
    static get manufacturerIcon() { return i18n.t(`manufacturerIcon.${i18n.language}`) }
    static get productIcon() { return i18n.t(`productIcon.${i18n.language}`) }
    static get manufacturerColor() { return i18n.t(`manufacturerColor.${i18n.language}`) }
    static get keyValues() { return i18n.t(`keyValues.${i18n.language}`) }
    static get email() { return i18n.t(`email.${i18n.language}`) }
    static get dragDropText() { return i18n.t(`dragDropText.${i18n.language}`) }
    static get freeTest() { return i18n.t(`freeTest.${i18n.language}`) }
    static get privacyText() { return i18n.t(`privacyText.${i18n.language}`) }
    static get privacyLink() { return i18n.t(`privacyLink.${i18n.language}`) }
    static get voluntaryText_1() { return i18n.t(`voluntaryText_1.${i18n.language}`) }
    static get voluntaryText_2() { return i18n.t(`voluntaryText_2.${i18n.language}`) }
    static get chooseFile() { return i18n.t(`chooseFile.${i18n.language}`) }
    static get nothingChoosen() { return i18n.t(`nothingChoosen.${i18n.language}`) }
}

export class AlertTexts {
    constructor() { }
    static get fillKeyValue() { return i18n.t(`fillKeyValue.${i18n.language}`) }
    static get successMessage() { return i18n.t(`successMessage.${i18n.language}`) }
    static get unexpectedError() { return i18n.t(`unexpectedError.${i18n.language}`) }
    static get createAgain() { return i18n.t(`createAgain.${i18n.language}`) }
    static get modalText() { return i18n.t(`modalText.${i18n.language}`) }
    static get modalTextBatteryPass() { return i18n.t(`modalTextBatteryPass.${i18n.language}`) }
    static get componentExists() { return i18n.t(`componentExists.${i18n.language}`) }
    static get outOfEmailLimit() { return i18n.t(`outOfEmailLimit.${i18n.language}`) }
}


