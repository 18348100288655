import React, { useRef } from 'react';
import { FormTexts } from '../../../language/Texts';
import './IconComponent.scss';

interface IconProps {
    setIcon: (icon: { iconFile: File, iconUrl: string }) => void,
    icon: { iconFile: File, iconUrl: string },
    type: string
}

function IconComponent({ setIcon, type, icon }: IconProps) {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files && event.target.files.length > 0 && event.target.files[0];
        if (file) {
            setIcon({ iconFile: file, iconUrl: URL.createObjectURL(file) });
        }
    };

    const openFileDialog = () => {
        if (inputRef.current) {
            inputRef.current.click(); // Trigger the file dialog
        }
    };

    return (
        <div className="input_pair">
            <label className='input_label'>{type}</label>
            <input
                data-testid='upload_image'
                ref={inputRef}
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: 'none' }}
            />
            <button type='button' className='select_file_button' onClick={openFileDialog}>{FormTexts.chooseFile}</button>
            {icon.iconFile?.name ?
                <span className='selected_file_name'>{icon.iconFile.name}</span> :
                <span className='selected_file_name'>{FormTexts.nothingChoosen}</span>}
        </div>
    )
}

export default IconComponent