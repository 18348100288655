import React from 'react'
import Snowfall from 'react-snowfall'
import snow_1 from '../../assets/snow_1.png'
import snow_2 from '../../assets/snow_2.png'
import snow_3 from '../../assets/snow_3.png'
import snow_4 from '../../assets/snow_4.png'
import { Utils } from '../../utils/Utils'

function SnowfallComponent() {

  const createSnowflakeElement = (image) => {
    const imageElement = document.createElement('img')
    imageElement.src = image
    return imageElement
  }

  const imageElements = [snow_1, snow_2, snow_3, snow_4]

  const images: HTMLImageElement[] = imageElements.map((imageElement) => {
    return createSnowflakeElement(imageElement)
  })

  const { speed, quantity, size } = Utils.getSnowVariables()
  return (
    <Snowfall
      snowflakeCount={quantity}
      images={images}
      speed={speed}
      radius={size}
      style={{ position: 'absolute' }}
    />
  )
}

export default SnowfallComponent
