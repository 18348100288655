import { WebViewTexts } from "../../../language/Texts";
import { Utils } from "../../../utils/Utils";

interface AttributeProps {
    keyValues: {
        key: string;
        value: string;
    }[] | undefined,
    isEditorView: boolean
}

function AttributeComponent({ keyValues, isEditorView }: AttributeProps) {
    return (
        <>
            {keyValues && keyValues?.length > 0 &&
                <div className='info_part'>
                    <div className={Utils.getInfoPartTitleClassName(isEditorView)}>{WebViewTexts.attributes}</div>
                    <div className='info_pair_wrapper'>
                        {keyValues?.map((kv, index) => (
                            <div key={index} className='info_pair'>
                                <div className={isEditorView ? 'info_title editor_info_title' : 'info_title'}>
                                    {kv.key}
                                </div>
                                <div className={isEditorView ? 'info editor_info' : 'info'}>
                                    {kv.value}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            }
        </>
    )
}

export default AttributeComponent
